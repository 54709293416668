*,
*::before,
*::after {
  margin: 0;
  line-height: calc(1em + 0.5rem);
  box-sizing: border-box;
}
html {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  font-family: 'Google Sans', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}


/*? Scrollbar */
body::-webkit-scrollbar {
  width: 4px;
}

body::-webkit-scrollbar-track {
  background: #ffffff;
}

body::-webkit-scrollbar-thumb {
  background-color: #fb923c;
  outline: 1px solid #fb923c;
  border-radius: 0.3rem;
}

.custom-scroll {
  scrollbar-width: thin; /* For Firefox */
  scrollbar-color: #fb923c #f0f0f0; /* For Firefox */
  

  /* For WebKit-based browsers like Chrome and Safari */
  &::-webkit-scrollbar {
    width: 4px;
    
  }

  &::-webkit-scrollbar-thumb {
    background-color: #fb923c;
    border-radius: 0.3rem;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
  }
}

.content {
  text-align: center;
  background-color: rgba(0, 0, 0, 0.7); /* Add a semi-transparent overlay to make text readable */
  padding: 20px;
  border-radius: 8px;
  max-width: 90%;
  margin: 0 auto;
}



/* PAGINATION */
.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  list-style: none;
  padding: 0;
  margin-top: 0.5rem;
  padding-bottom: 0.5rem;
  gap: 2px;
  user-select: none;
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  -webkit-user-select: none; /* Safari */
}

.pagination a {
  display: inline-block;
  padding: 0.1rem 0.5rem; /* Reduced padding for a more compact size */
  text-decoration: none;
  color: #2563EB; /* Primary color for the outline */
  border: 1px solid #2563EB; /* Add a border for the outline effect */
  border-radius: 0.25rem;
  transition: color 0.3s ease, background-color 0.3s ease, border-color 0.3s ease;
  
}

.pagination a:hover {
  background-color: #2563EB; /* Darker color on hover */
  color: #fff; /* Text color on hover */
}

.pagination .active a {
  background-color: #2563EB; /* Dark background for the active page */
  color: #fff; /* Text color for the active page */
}

.pagination .disabled a {
  pointer-events: none;
  background-color: #bdc3c7; /* Lighter color for disabled buttons */
  color: #777; /* Adjust text color for disabled buttons */
  border-color: #bdc3c7; /* Adjust border color for disabled buttons */
}

/* Add these styles for the next and previous buttons specifically */

.pagination .prev-next a {
  background-color: transparent; /* Transparent background for outline effect */
  border-color: #2563EB; /* Primary color for the outline */
  color: #2563EB; /* Primary color for the text */
}

.pagination .prev-next a:hover {
  background-color: #2563EB; /* Darker color on hover for next and previous buttons */
  color: #fff; /* Text color on hover for next and previous buttons */
  border-color: #2980b9; /* Darker border color on hover for next and previous buttons */
}

@media only screen and (max-width: 600px) {
  .pagination {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    overflow-x: auto; /* Allow horizontal scrolling on small screens if necessary */
    padding: 0.5rem; /* Adjust padding for better spacing */
  }
}

/* Remove increment and decrement arrows from number inputs */
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button,
input[type="number"] {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}


/* REGISTER PAGE */
body {
  background-image: url('https://raahtechservices.com/branding/bg3.jpg');
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
}
.raah-logo {
  max-width: 100px;
  margin-bottom: 20px;
}
.info-box {
  border: 2px solid #4a5568;
  padding: 20px;
  border-radius: 10px;
}
.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.contact-info p {
  margin: 5px 0;
}
.contact-info i {
  margin-right: 5px;
}
.agreement-section {
  background-color: #ffffff;
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
}
.agreement-section p {
  font-size: 14px;
  margin-bottom: 10px;
}
.agreement-checkbox {
  margin-top: 10px;
}



@keyframes gradient {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.animate-gradient {
  animation: gradient 15s ease infinite;
}

.poly{
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 5% 50%, 0% 0%)
}

.poly-r{
  clip-path: polygon(95% 0%, 100% 50%, 95% 100%, 0% 100%, 0 50%, 0% 0%);
}
.poly-l{
  clip-path: polygon(100% 0, 100% 50%, 100% 100%, 0% 100%, 5% 50%, 0% 0%);
}

/* SweetAlert custom button styles */
.swal-confirm-btn {
  background-color: #4caf50 !important; /* Green background */
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
}

.swal-cancel-btn {
  background-color: #f44336 !important; /* Red background */
  color: white !important;
  border: none !important;
  padding: 10px 20px !important;
  font-size: 14px !important;
  border-radius: 4px !important;
  margin-right: 10px !important;
}

.ant-picker-ok > button {
  color: #2563EB ;
}

.vertical-text {
  writing-mode: vertical-rl; /* Rotate text vertically */
  text-orientation: mixed; /* Make sure characters appear in the correct orientation */
  transform: rotate(180deg); /* Reverse the text flow for better readability */
  white-space: nowrap; /* Prevent text wrapping */
}

/* styles.css */
.react-calendar-timeline {
  background-color: #f9f9f9; /* Light background for the timeline */
}

.react-calendar-timeline .rct-item {
  transition: transform 0.2s; /* Smooth transition for items */
}

.react-calendar-timeline .rct-item:hover {
  transform: scale(1.02); /* Slightly enlarge item on hover */
}

.date-range-picker {
  position: relative;
  z-index: 9999; /* Ensure the date picker stays on top */
}

.datepicker-input {
  margin: 5px;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.table-container {
  padding: 20px;
}

.data-view {
  margin-top: 20px;
}
@media (max-width: 768px) {
  .date-range-picker {
    position: absolute;
    top: 10px;
    left: 10px;
    z-index: 9999;
  }
}

